<template>
    <v-container>
        <helper :groupId="3" :autostart="true" :closeOnEnd='true'/>
        <v-row  id="battleType">
            <v-col cols="6" class="py-0">
                Тип
            </v-col>
            <v-col cols="6"  class="py-0">
                <v-select
                   
                    v-model="battle.type"
                    :items="types"
                    item-text="state"
                    item-value="abbr"
                    label="Тип"
                    persistent-hint
                    single-line
                    ></v-select>
            </v-col>
        </v-row>
        <div v-if="battle.type">
            <v-row  id="battleRounds">
                <v-col class="py-0">Количество раундов</v-col><v-col class="py-0">
                    <v-select
                       
                        v-model="battle.rounds"
                        :items="[1,3,5]"
                        label="Количество раундов"
                        persistent-hint
                        single-line
                        ></v-select></v-col>
            </v-row>
            <v-row id="battleQ">
                <v-col class="py-0">Количество вопросов в раунде</v-col><v-col class="py-0"><v-select
                        
                        v-model="battle.questionsCount"
                        :items="[3,5,10]"
                        label="Количество раундов"
                        persistent-hint
                        single-line
                        ></v-select></v-col>
            </v-row>
            <v-row id="battleTime">
                <v-col class="py-0">Время на ответ, секунд</v-col><v-col class="py-0"><v-text-field type="number" v-model="battle.responseTime" label="Время на ответ, секунд"></v-text-field></v-col>
            </v-row>
            <v-row v-if="battle.type=='artificial' || battle.type=='commandArtificial'">
                <v-col class="py-0">Сложность</v-col><v-col class="py-0"><v-select
                        v-model="battle.complexity"
                        :items="[{value:1,text:'Легкая'},{value:2,text:'Средняя'},{value:3,text:'Сложная'}]"
                        label="Сложность"
                        persistent-hint
                        single-line
                        ></v-select></v-col>
            </v-row>
            <v-row v-if="battle.type=='commandArtificial' || battle.type=='command'">
                <v-col class="py-0">Количество участников в команде</v-col><v-col class="py-0"><v-select
                        @change="updateCommands()"
                        v-model="battle.numberOfParticipants"
                        :items="[2,5,10]"
                        label="Количество участников в команде"
                        persistent-hint
                        single-line
                        ></v-select></v-col>
            </v-row>
            <v-row v-if="battle.type=='1x1'">
                <v-col class="py-0"><center><h2>Выберите соперника</h2></center></v-col>
            </v-row>
            <v-row v-if="battle.type=='1x1'">
                <v-col class="py-0"><v-text-field v-model="userString" label="Введите имя для поиска..."></v-text-field></v-col>
            </v-row>
            <v-row v-if="battle.type=='1x1'">
                <v-col class="py-0">

                    <center>
                        <v-slide-group

                            class="pa-4"
                            center-active
                            show-arrows="false"
                            >
                            <v-slide-item
                                v-for="u in users"
                                :key="u.id"
                                v-slot="{ active, toggle }"
                                >
                                <v-scale-transition>
                                    <div class='pa-2'>
                                        <v-avatar
                                            style='cursor:pointer;'
                                            color="grey lighten-1"
                                            class="ma-4"
                                            height="150"
                                            width="150"
                                            @click="toggle(); battle.rightSide=u.id"
                                            >
                                            <img :src="u.avatar" v-if="u.avatar">
                                            <v-icon v-else-if="u.id==0" size='96' color="primary">mdi-account-cowboy-hat</v-icon>
                                            <v-icon v-else size='96'>mdi-account</v-icon>
                                            <v-overlay
                                                :absolute="true"
                                                :value="active"
                                                ><v-icon v-if="active" color="green" style="position: absolute; top:-48px; left:-48px;" size='96'>mdi-check</v-icon></v-overlay>
                                        </v-avatar>
                                        <h4>{{u.name}}</h4>
                                    </div>
                                </v-scale-transition>
                            </v-slide-item>
                        </v-slide-group>
                    </center>
                </v-col>
            </v-row>

            <div v-if="battle.type=='command' || battle.type=='commandArtificial'" >
                <v-row class="my-3"><v-col><v-card><center>
                                <h3 class="pa-3 text--secondary">Ваша команда</h3><v-row class="blue lighten-4 text-center ma-0"><v-col class="pr-0 pb-0 pt-3"><userSearch class="py-0" :value.sync="leftSearch"/></v-col><v-col  class="pl-0" cols="auto"><v-btn :disabled="battle.leftCommand.length+1>=battle.numberOfParticipants" @click="addToCommand('left')"><v-icon>mdi-plus</v-icon></v-btn></v-col></v-row>
                                <v-avatar 
                                    color="grey lighten-1"
                                    class="ma-3"
                                    height="48"
                                    width="48"
                                    size="48px"
                                    >
                                    <img v-if='$auth.user().profile.avatar'
                                         :src="$auth.user().profile.avatar"
                                         >
                                    <v-icon
                                        v-else
                                        size="96px"
                                        color="blue"
                                        >mdi-user</v-icon>
                                </v-avatar>
                                <v-avatar 
                                    v-for='user in battle.leftCommand'
                                    :key='user.id'
                                    color="grey lighten-1"
                                    class="ma-3"
                                    height="48"
                                    width="48"
                                    size="48px"
                                    >
                                    <img v-if='user.avatar'
                                         :src="user.avatar"
                                         >
                                    <v-icon
                                        v-else
                                        size="48px"
                                        color="blue"
                                        >mdi-account</v-icon>
                                </v-avatar>
                            </center>
                        </v-card></v-col><v-col v-if="battle.type=='command'"><v-card style="min-height:188px;"><center>
                                <h3 class="pa-3 text--secondary">Соперник</h3><v-row class="blue lighten-4 text-center ma-0"><v-col class="pr-0 pb-0 pt-3"><userSearch class="py-0" :value.sync="rightSearch"/></v-col><v-col  class="pl-0" cols="auto"><v-btn :disabled="battle.rightCommand.length+1>=battle.numberOfParticipants" @click="addToCommand('right')"><v-icon>mdi-plus</v-icon></v-btn></v-col></v-row>
                                                                <v-avatar 
                                    v-for='user in battle.rightCommand'
                                    :key='user.id'
                                    color="grey lighten-1"
                                    class="ma-3"
                                    height="48"
                                    width="48"
                                    size="48px"
                                    >
                                    <img v-if='user.avatar'
                                         :src="user.avatar"
                                         >
                                    <v-icon
                                        v-else
                                        size="48px"
                                        color="blue"
                                        >mdi-account</v-icon>
                                </v-avatar>
                            </center>
                        </v-card>
                    </v-col>
                </v-row>

            </div>
            <center><v-btn color='primary' @click='start()' id="battleStart">Начать!</v-btn></center>
        </div>
    </v-container>
</template>

<script>
    import helper from '@/views/helper'
    import userSearch from './userSearch'
            const debounce = function (f, ms) {
                let timer = null;
                return function (...args) {
                    const onComplete = () => {
                        f.apply(this, args);
                        timer = null;
                    }
                    if (timer) {
                        clearTimeout(timer);
                    }
                    timer = setTimeout(onComplete, ms);
                };
            }
    export default {
        name: "index",
        components: {
            userSearch,
            helper
        },
        data: () => ({
                battle: {
                    type: '1x1',
                    rounds: 3,
                    questionsCount: 3,
                    responseTime: 30,
                    complexity: 2,
                    numberOfParticipants: 3,
                    rightSide: null,
                    user: null,
                    leftCommand: [],
                    rightCommand: []
                },
                leftSearch: '',
                rightSearch: '',
                types: [
                    {state: '1x1', abbr: '1x1'},
                    {state: '1xИИ', abbr: 'artificial'},
                    {state: 'Команда на Команду', abbr: 'command'},
                    {state: 'Команда против ИИ', abbr: 'commandArtificial'}
                ],
                stage: 1,
                userString: '',
                users: [],
                topics: null
            }),
        watch: {
            userString() {
                this.search()
            },
        },
        methods: {
            updateCommands(){
                if (this.battle.leftCommand.length + 1 >= this.battle.numberOfParticipants){
                    this.battle.leftCommand.splice(this.battle.numberOfParticipants-1, this.battle.leftCommand.length);
                }
                if (this.battle.rightCommand.length >= this.battle.numberOfParticipants){
                    this.battle.rightCommand.splice(this.battle.numberOfParticipants, this.battle.rightCommand.length);
                }
            },
            addToCommand(side) {
                if (side == 'left') {
                    if(!this.leftSearch){
                        return
                    }
                    if (this.$auth.user().id==this.leftSearch.id 
                            || (this.leftSearch && (this.battle.leftCommand.findIndex(el => el.id == this.leftSearch.id) != -1 || this.battle.rightCommand.findIndex(el => el.id == this.leftSearch.id) != -1))){
                        alert('Уже в списке')
                        return;
                    }
                    if (this.battle.leftCommand.length + 1 >= this.battle.numberOfParticipants)
                    {
                        return 'Команда заполнена';
                    }
                    this.battle.leftCommand.push(this.leftSearch)
                    this.leftSearch=null
                } else {
                    if(!this.rightSearch){
                        return
                    }
                    if (this.$auth.user().id==this.rightSearch.id 
                            || (this.rightSearch && (this.battle.rightCommand.findIndex(el => el.id == this.rightSearch.id) != -1 || this.battle.leftCommand.findIndex(el => el.id == this.rightSearch.id) != -1))){
                        alert('Уже в списке')
                        return;
                    }
                    if (this.battle.rightCommand.length >= this.battle.numberOfParticipants)
                    {
                        return 'Команда заполнена';
                    }
                    this.battle.rightCommand.push(this.rightSearch)
                    this.rightSearch=null
                }
            },
            start() {
                if(this.battle.type=='command' || this.battle.type=='commandArtificial'){
                    this.battle.leftIds=[]
                    this.battle.rightIds=[]
                    for(var leftCommand of this.battle.leftCommand){
                        this.battle.leftIds.push(leftCommand.id)
                    }
                    for(var rightCommand of this.battle.rightCommand){
                        this.battle.rightIds.push(rightCommand.id)
                    }
                }
                this.$http.post('battle/new-battle',
                        this.battle
                        ).then(() => {
                    this.$emit('refresh')
                }).catch(e => {
                    alert(e.response.data)
                })
            },
            search: debounce(function () {
                this.fetchUsers()
            }, 500),
            fetchUsers() {
                var random = {id: 0, name: 'Случайный соперник'}
                this.$http.get('chat/user-search?q=' + this.userString + '&page=1').then(({ data }) => {
                    this.users = [random]
                    this.users.push(...data)
                })
            },
        },
        mounted() {
            this.fetchUsers()
        }

    }
</script>