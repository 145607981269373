<template>
    <v-container>
        <helper :groupId="3" :autostart="true" :closeOnEnd='true'/>
        <div v-if="chooseSection">
            <h2>Выберите тему</h2>
            <v-item-group>
                <v-container>
                    <v-row>
                        <v-col
                            v-for="section in sections"
                            :key="section.id"
                            cols="12"
                            md="4"
                            >
                            <v-item v-slot="{ active, toggle }" >
                                <v-card
                                    :color="active ? 'primary' : 'blue-grey'"
                                    class="d-flex align-center"
                                    dark
                                    height="200"
                                    @click="toggle();setSection(section.id)"
                                    >
                                    <div style='width: 100%'>
                                        <center> {{section.name}}</center>
                                    </div>
                                </v-card>
                            </v-item>
                        </v-col>
                    </v-row>
                </v-container>
            </v-item-group>
        </div>
        <div v-if="questionProc">
            <h3>Раунд {{battle.curRound}} из {{battle.rounds}}</h3>
                    <center><h3>Вопрос {{questionNumber}} из {{battle.questionsCount}}</h3>
                            
                                <v-progress-circular
      :rotate="270"
      :size="100"
      :width="15"
      :value="100*countdown/battle.responseTime"
      :color="countdown<10?'red':'green'"
    >
      {{ countdown }}
    </v-progress-circular>
                            </center>
            <div style="display:block;">
                <v-img v-if="question.file" contain class="ma-3" max-height="300" :src="question.file">
                </v-img>
            </div>
            <h3 class='ma-3'>{{question.text}}</h3>
            <span v-if='question.several'>*В этом вопросе может быть больше одного ответа</span>
            <v-row class="text-center px-7 pt-3">
                <v-col cols="12" lg="6" v-for="answer in question.answers" :key="answer.id">
                    <v-card elevation="2" class="pa-3"
                            shaped>

                        <v-checkbox class="ma-0"
                                    :multiple="question.several"
                                    :label="answer.text"
                                    v-model="question.correct"
                                    color="green"
                                    :value="answer.id"
                                    hide-details

                                    ></v-checkbox>
                    </v-card>
                </v-col>
            </v-row>
            <center><v-btn @click="setAnswer()" class='ma-5' color='blue'>Ответить</v-btn></center>
        </div>
    </v-container>
</template>

<script>
import helper from '@/views/helper'
    export default {
        name: "index",
        props: {
            battleId: Number
        },
        components:{
          helper  
        },
        data: () => ({
                chooseSection: false,
                questionProc: false,
                sections: [],
                battle: {},
                question: {},
                answer:{},
                questionNumber: 1,
                countdown: 30,
                timer: 0
            }),
        watch: {
            countdown(time) {
                if (time <= 0) {
                    this.stopTimer()
                    this.setAnswer()
                }
            }
        },
        methods: {
            setAnswer() {

                this.$http.get('battle/answer?answerId=' + this.answer.id + '&correct=' + this.question.correct).then(() => {
                    this.fetchBattle()
                })
            },
            startTimer() {
                this.timer = setInterval(() => {
                    if(this.countdown>0) {
                        this.countdown--
                    }
                }, 1000)
            },
            stopTimer() {
                clearTimeout(this.timer)
            },
            setSection(id) {
                this.$http.get('battle/choose-section?sectionId=' + id + '&battleId=' + this.battleId).then(() => {
                    this.fetchBattle()
                })
            },
            fetchBattle() {
                this.stopTimer()
                this.$http.get('battle/get-battle?id=' + this.battleId).then((response) => {
                    this.chooseSection = false
                    this.questionProc = false
                    if (response.data.mode) {
                        if (response.data.mode == 'question') {
                            this.questionProc = true
                            this.$set(this,'battle',response.data.battle)
                            this.$set(this,'question',response.data.question)
                            this.$set(this,'answer',response.data.answer)
                            this.$set(this,'questionNumber',response.data.questionNumber)
                            this.$set(this,'countdown',response.data.countdown)
                            this.startTimer()
                        }
                        if (response.data.mode == 'refresh') {
                            this.fetchBattle()
                        }
                        if (response.data.mode == 'chooseSection') {
                            this.fetchSections()
                            this.chooseSection = true
                        }
                        if (response.data.mode == 'waitForOpponent' || response.data.mode == 'done') {
                            this.$emit('refresh')
                        }
                    }
                })
            },
            fetchSections() {
                this.$http.get('battle/get-sections').then((response) => {
                    this.sections = response.data
                })
            }
        },
        destroyed() {
            this.stopTimer()
        },
        mounted() {

        }

    }
</script>