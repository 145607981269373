<template>
    <v-autocomplete 
        :loading="usersLoading" 
        :filter="function(){return true}" 
        :items="users" 
        v-model="inputVal" 
        :search-input.sync="search"
        item-value="id" 
        return-object
        label="Поиск..."
        item-text="name"/>
</template>
<script>


    export default {
        name: "index",
        props: ['value'],
        data: () => ({

                users: [],
                usersLoading: false,
                search: null
            }),
        watch: {
            search() {
                // Items have already been loaded
                //if (this.permissionUsers.length > 0) return

                // Items have already been requested
                if (this.usersLoading)
                    return

                this.usersLoading = true
                this.users = []
                // Lazily load input items
                this.$http.get('user/user-search?q=' + this.search)
                        .then(res => {
                            this.$set(this, 'users', res.data)
                            // this.permissionUsers = res.data
                        })
                        .catch(err => {
                            console.log(err)
                        })
                        .finally(() => (this.usersLoading = false))
            },
        },
  computed: {
    inputVal: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('update:value', val);
      }
    }
  }
    };
</script>