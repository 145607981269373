<template>
    <div>
        <v-btn v-observe-visibility="visibilityChanged" fab id='helper' @click='tourStart()' style='position: absolute; top:20px; right:20px; z-index:10'><v-icon large>mdi-head-question-outline</v-icon></v-btn>
        <v-tour :name="TourName" :steps="steps" :options="tourOptions" :callbacks="tourCallbacks"></v-tour>
    </div>
</template>
<style>
    .v-tour__target--highlighted {
        box-shadow: 0 0 0 99999px rgba(0,0,0,.4) !important;
        pointer-events: none;
    }
</style>
<script>

    export default {
        name: "index",
        props: {
            groupId: Number,
            autostart: Boolean,
            delay:Number,
            closeOnEnd:Boolean
        },
        data() {
            return{
                tourOptions: {
                    id: 3,
                    highlight: true,
                    useKeyboardNavigation: true,
                    labels: {
                        buttonSkip: 'Закончить',
                        buttonPrevious: 'Назад',
                        buttonNext: 'Далее',
                        buttonStop: 'ОК'
                    }
                },
                steps: [
                ],
                tourCallbacks: {
                    onStop: this.tourStop,
                    onNextStep: this.playNextAudio,
                    onPreviousStep: this.playPrevAudio,
                    onStart: this.playFirstAudio,
                    onFinish: this.finish
                },
                autostarted:false,
                audio: null
            }
        },
        computed:{
            TourName(){
                return 'first'+this.groupId
            }
        },
        mounted() {
            if (this.autostart == true) {
                if(this.delay){
                    setTimeout(this.getSteps,this.delay)
                } else {
                    this.getSteps()
                }
                
                this.autostarted=true
            }

        },
        beforeDestroy: function () {
            this.$tours[this.TourName].stop()
        },
        methods: {
            visibilityChanged(isVisible,entry) {
                if (isVisible == false && entry.boundingClientRect.x==0 && entry.boundingClientRect.y==0) {
                    this.$tours[this.TourName].stop()
                }
                console.log(entry)
            },
            getSteps() {
                this.$http.get('help/get',
                        {
                            params: {
                                groupId: this.groupId
                            }
                        }).then(response => {
                    this.$set(this, 'steps', response.data.steps)
                    this.$nextTick(() => {
                        if(this.autostarted==false || !response.data.done){
                            this.$tours[this.TourName].start()
                        }
                        this.autostarted=false
                        
                    })

                })
            },
            playFirstAudio(currentStep)
            {
                if (this.audio) {
                    this.audio.pause()
                }
                this.audio = new Audio(this.steps[0].file) // path to file
                this.audio.play()
                var vm = this
                this.audio.onended = function () {
                    if (currentStep == vm.steps.length)
                    {
                        vm.$tours[vm.TourName].stop()
                    } else
                    {
                        vm.$tours[vm.TourName].nextStep()
                    }
                }
            },
            playPrevAudio(currentStep)
            {
                this.audio.pause()
                this.audio = new Audio(this.steps[currentStep - 1].file) // path to file
                this.audio.play()
            },
            playNextAudio(currentStep)
            {
                this.audio.pause()
                this.audio = new Audio(this.steps[currentStep + 1].file) // path to file
                this.audio.play()
                var that = this
                this.audio.onended = function () {
                    if(that.$tours[that.TourName].isLast){
                        if(that.closeOnEnd){
                            that.$tours[that.TourName].stop()
                        }
                    }
                    else
                    {    
                        that.$tours[that.TourName].nextStep()
                    }
                }
            },
            finish(){
   
            },
            tourStop() {

                if (this.audio) {
                    this.audio.pause()
                }
                    this.$http.get('profile/finish-help',
                            {
                                params: {
                                    id: this.groupId
                                }
                            })
 
                /*
                 if (!this.$auth.user().helpStory.find(el => el == this.tourOptions.id))
                 {
                 this.$http.get('profile/finish-help',
                 {
                 params: {
                 id: this.tourOptions.id
                 }
                 }).then(response => {
                 this.$auth.user().helpStory.push(response.data)
                 })
                 }
                 * 
                 */
            },
            tourStart() {
                this.getSteps()

            },
        },
    };
</script>