<template>
    <div>
        <helper :groupId="2" :autostart="true" :closeOnEnd='true'/>
        <v-dialog v-model="battleModal" max-width="900">
            <v-card>
                <v-card-text class="pa-3"><battle v-if="curBattle" ref="battle" :battleId.sync="curBattle.id" @refresh='getBattleList();battleModal=false'></battle></v-card-text>
            </v-card>
        </v-dialog>
        <v-dialog v-model="revengeModal" max-width="300">
            <v-card>
                <v-card-text class="pa-3"><center>
                        <h3>Будет создана копия выбранного матча</h3>
                        <v-btn @click="revenge()" class="ma-3" color="primary">Начать</v-btn>
                    </center></v-card-text>
            </v-card>
        </v-dialog>
        <div id="battles">
            <v-card class="mb-7">
                <v-card-text class="pa-5">
                    <v-row justify="space-between">
                        <v-col cols="12" lg="4">
                            <v-pagination
                                v-model="page"
                                class="ma-0 float-left"
                                :length="pages"
                                :total-visible="7"
                                ></v-pagination>
                        </v-col>
                        <v-col cols="12" lg="4" class="justify-end align-center d-flex">
                            <v-dialog v-model="newBattleModal"  max-width="900px">
                                <template v-slot:activator="{ on }">
                                    <v-btn id="startBattle" color="info" v-on="on">Новый турнир</v-btn>
                                </template>
                                <v-card>
                                    <v-card-text class="pa-3"><newBattle @refresh='getBattleList();newBattleModal=false'></newBattle></v-card-text>
                                </v-card>
                            </v-dialog>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>

            <v-row justify="center">
                <v-col cols="12" sm="6" lg="4" v-for="(battle, index) in battles" :key="index">
                    <v-card class="mb-7">
                        <v-card-text class="pa-5 text-center">
                            <v-row>
                                <v-col class="pa-0">
                                    <v-avatar
                                        size="96px"
                                        >
                                        <img v-if='$auth.user().profile.avatar'
                                             :src="$auth.user().profile.avatar"
                                             >
                                        <v-icon
                                            v-else
                                            size="96px"
                                            color="blue"

                                            >mdi-user</v-icon>
                                    </v-avatar><br>
                                    <small class="subtitle-2">{{$auth.user().profile.last_name}} {{$auth.user().profile.name}} {{$auth.user().profile.second_name}}</small>
                                </v-col>
                                <v-col class="pa-0">
                                    <h1 class="pt-10">
                                        <div  v-if='!battle.commandId'>
                                            <i v-if="battle.type=='1x1'">1 х 1</i>
                                            <i v-if="battle.type=='artificial'">1 х ИИ</i>                                        
                                        </div><div v-else>
                                            <i v-if="battle.type=='1x1'">{{battle.numberOfParticipants}} х {{battle.numberOfParticipants}}</i>
                                            <i v-if="battle.type=='artificial'">{{battle.numberOfParticipants}} х ИИ</i>
                                        </div>
                                    </h1>
                                </v-col>
                                <v-col class="pa-0">


                                    <v-icon
                                        v-if='battle.commandId'
                                        size="96px"
                                        style='position:absolute'
                                        color="blue"

                                        >mdi-account-group</v-icon>
                                    <v-avatar size="96px">
                                        <img v-if='battle.avatar.type=="img"'
                                             :src="battle.avatar.val"
                                             >
                                        <v-icon
                                            v-else
                                            size="89px"
                                            v-text="battle.avatar.val"
                                            color="blue"
                                            ></v-icon>

                                    </v-avatar><br>
                                    <small class="subtitle-2 " >{{ battle.avatar.name }}<div v-if="battle.avatar.name=='ИИ'"> 
                                            (<span v-if='battle.complexity==1'>Легкая</span>
                                            <span v-if='battle.complexity==2'>Средняя</span>
                                            <span v-if='battle.complexity==3'>Высокая</span> сложность)</div></small>
                                </v-col>
                            </v-row>


                            <div class="mt-3" style="height:78px;">
                                <div v-for='i in battle.curRound' :key='i'>
                                    <v-row><v-col class="pa-1"><v-btn-toggle v-for='(ans,index) in roundAnswers(battle,i,0)' :key='index'><v-chip style="height:24px;" label :color="ans.correct==1?'green':'red'" class="mr-1"></v-chip></v-btn-toggle></v-col>
                                        <v-col class="pa-1">Раунд {{i}}</v-col>
                                        <v-col class="pa-1"><v-btn-toggle v-for='(ans,index) in roundAnswers(battle,i,1)' :key='index'><v-chip style="height:24px;"  label :color="ans.correct==1?'green':'red'" class="mr-1"></v-chip></v-btn-toggle></v-col>
                                    </v-row>
                                </div>
                            </div>

                        </v-card-text>

                        <div class="pa-1 blue lighten-4 text-center">
                            <div v-if="battle.endTime">
                                <div v-if="!battle.commandId">
                                    <v-chip class="ma-2" v-if="battle.winner===0">
                                        Ничья
                                    </v-chip>
                                    <v-chip class="ma-2" color="green"  text-color="white" v-else-if="battle.winner==$auth.user().id">
                                        Победа
                                    </v-chip>
                                    <v-chip class="ma-2" v-else color="red" text-color="white">
                                        Поражение
                                    </v-chip>
                                </div>
                                <div v-else>
                                    <v-row><v-col>

                                            <v-tooltip bottom>
                                                <template v-slot:activator="{ on, attrs }">
                                                    <span
                                                        v-bind="attrs"
                                                        v-on="on"
                                                        ><v-icon>mdi-account</v-icon></span>
                                                </template>
                                                <span>Личный зачёт</span>
                                            </v-tooltip>
                                            <v-chip class="ma-2" v-if="battle.winner===0">
                                                Ничья
                                            </v-chip>
                                            <v-chip class="ma-2" color="green"  text-color="white" v-else-if="battle.winner==$auth.user().id">
                                                Победа
                                            </v-chip>
                                            <v-chip class="ma-2" v-else color="red" text-color="white">
                                                Поражение
                                            </v-chip>
                                        </v-col>
                                        <v-col>    <v-tooltip bottom>
                                                <template v-slot:activator="{ on, attrs }">
                                                    <span
                                                        v-bind="attrs"
                                                        v-on="on"
                                                        ><v-icon>mdi-account-group</v-icon></span>
                                                </template>
                                                <span>Командный зачёт
                                                                                        
                                                </span>
                                            </v-tooltip>
                                            <v-chip class="ma-2" v-if="!battle.command.winner">
                                                Ожидание
                                            </v-chip>
                                            <v-chip class="ma-2" v-if="battle.command.winner===0">
                                                Ничья
                                            </v-chip>
                                            <span v-if='battle.command.winner'>
                                            <v-chip class="ma-2" color="green"  text-color="white" v-if="battle.command.winner=='leftSide' && battle.leftSide==$auth.user().id">
                                                Победа
                                            </v-chip> 
                                            <v-chip class="ma-2" v-else color="red" text-color="white">
                                                Поражение
                                            </v-chip> 
                                            </span>
                                        </v-col></v-row>
                                </div>
                            </div>
                            <div v-else>
                                <v-chip class="ma-2" color="primary" text-color="white" v-if="battle.status=='yourTurn' || battle.status=='youChooseSection'">
                                    <v-btn depressed small color="primary" class="mr-2" @click="openBattle(battle)">Ваш ход</v-btn>
                                </v-chip>
                                <div v-if="battle.status=='waitForAccept'" class="pa-3">
                                    <v-btn depressed small color="primary" class="mr-2" @click="accept(battle.id)">Принять</v-btn>
                                    <v-btn depressed small color="red" class="mr-2" v-if="battle.rightSide" @click="reject(battle.id)">Отклонить</v-btn>
                                </div>

                                <v-chip class="ma-2"  v-if="battle.status=='opponentTurn' || battle.status=='opponentChooseSection'">
                                    Ожидание соперника
                                </v-chip>
                                <v-chip class="ma-2"  v-if="battle.status=='waitForOpponentAccept'">
                                    Ожидание подтверждения соперника
                                </v-chip>
                            </div>
                        </div>
                        <v-card-text class="px-4 py-3">
                            <v-row>
                                <v-col cols="6" class="text-left">                                            
                                    <v-rating
                                        :value="rating(battle).rating"
                                        color="yellow darken-3"
                                        background-color="grey darken-1"
                                        empty-icon="$ratingFull"
                                        half-increments

                                        readonly
                                        ></v-rating>

                                </v-col>
                                <v-col cols="6" class="text-center">
                                    <v-btn class="float-right" color="primary" v-if="battle.endTime && !battle.commandId" @click="revengeModal=true; curBattle=battle">Реванш</v-btn>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </div>
    </div>
</template>

<script>
    import newBattle from './newBattle'
    import battle from './battle'
    import helper from '@/views/helper'
    export default {
        name: "index",
        data: () => ({
                page: 1,
                pages: 1,
                revengeModal: false,
                newBattleModal: false,
                battleModal: false,
                battles: [],
                curBattle: {},
                interval: null,
                avatars: [],
                answers: []
            }),
        components: {
            newBattle,
            battle,
            helper
        },
        methods: {
            reject(id) {
                this.$http.get('battle/reject?battleId=' + id).then(() => {
                    this.getBattleList()
                })
            },
            accept(id) {
                this.$http.get('battle/accept?battleId=' + id).then(() => {
                    this.getBattleList()
                })
            },
            revenge() {
                this.$http.get('battle/revenge?battleId=' + this.curBattle.id).then(() => {
                    this.revengeModal = false
                    this.getBattleList()
                })
            },
            rating(battle) {
                var questionsCount = 0
                var sum = 0
                for (var answer of this.answers[battle.id]) {
                    if (answer.userId == this.$auth.user().id) {
                        questionsCount++;
                        if (answer.correct == 1) {
                            sum++;
                        }
                    }
                }
                return {rating: 5 * sum / questionsCount, questions: questionsCount, correct: sum}
            },
            roundAnswers(battle, round, side) {
                var arr = []
                for (var answer of this.answers[battle.id]) {
                    if (side == 0) {
                        if (answer.userId == this.$auth.user().id && answer.round == round) {
                            if (answer.correct == 1) {
                                arr.push({correct: true})
                            } else {
                                arr.push({correct: false})
                            }

                        }
                    } else {
                        if (answer.userId != this.$auth.user().id && answer.round == round) {
                            if (answer.correct == 1) {
                                arr.push({correct: true})
                            } else {
                                arr.push({correct: false})
                            }

                        }
                    }
                }
                return arr
            },
            openBattle(battle) {
                this.battleModal = true
                this.curBattle = battle
                this.$nextTick(() => {
                    this.$refs.battle.fetchBattle()
                })

            },
            getBattleList() {
                this.$http.get('battle/get-battles?page=' + this.page).then((response) => {
                    this.battles = response.data.battles
                    this.avatars = response.data.avatars
                    this.answers = response.data.answers
                    this.pages = response.data.pages
                    for (var key in this.battles) {
                        this.battles[key].avatar = this.avatars[this.battles[key].id]
                        this.battles[key].answers = this.answers[this.battles[key].id]
                    }
                })
            }
        },
        watch: {
            page() {
                this.getBattleList()
            }
        },
        created() {
            this.interval = setInterval(() => this.getBattleList(), 10000);
            this.getBattleList()
        },
        destroyed() {
            clearTimeout(this.interval)
        },

    }
</script>